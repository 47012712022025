import {gsapSingleton} from "../gsap";
const gsap = gsapSingleton();
import Component from '../models/Component';


export default class CtaLanguage extends Component {
    constructor(element, context) {
        super(element, context);

        let languages = this.element.find('.nav-languages');
            
        this.element.on('mouseenter', function() {
            gsap.to(languages, {
                opacity: 1,
                height: '325px',
                duration: 0.5,
                ease: 'power2.inOut',
            });
            
        });
            
        this.element.on('mouseleave', function() {
            gsap.to(languages, {
                    opacity: 0,
                    height: 0,
                    duration: 0.5,
                    ease: 'power2.inOut',
            });
        });				
      
    }
}